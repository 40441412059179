import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { type TimelineEntry, TimelineEntryType } from "@structured/utils/timeline";

import styles from "./TimelineTimes.module.scss";

export const TimelineTimes = (props: { entry: TimelineEntry }) => {
  const genTimes = (): Date[] => {
    const start = props.entry.start;
    const duration = props.entry.duration;
    if (duration === 0 || duration === 1) {
      return [start];
    }
    const end = new Date(start.getTime() + duration * 60 * 1000);
    return [start, end];
  };

  return (
    <Box
      className={styles.TimelineTimes}
      justifyContent={props.entry.duration === 1 ? "space-evenly" : "space-between"}
      height={"100%"}
      display={"flex"}
      flexDirection={"column"}
      py={"0.5rem"}
    >
      {props.entry.type !== TimelineEntryType.TASK ? (
        <></>
      ) : (
        <>
          {genTimes().map((time, i) => (
            <Typography
              key={i}
              component="span"
              fontSize={"0.625rem"}
              color={"text.secondary"}
              fontWeight={400}
              textAlign={"center"}
            >
              {time.toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
              })}
            </Typography>
          ))}
        </>
      )}
    </Box>
  );
};

// MORE COMPLEX VARIANTE IN CASE WE DECIDE TO BRING IT IN
// const createTimePos = (
//   start: Date,
//   day: number,
//   postionModifier: number
// ): TimePosition => {
//   // WHEN NO POSITION MODIFIER IS GIVEN
//   // let position = CGFloat((date.timeIntervalSince1970 - start.timeIntervalSince1970)
//   //   / (end.timeIntervalSince1970 - start.timeIntervalSince1970))
//   // return self.init(date: date, day: day, position: position)
//
//   return {
//     timeStr: "YEAH",
//     position: 20,
//   };
// };
//
// const genTimes = (): TimePosition[] => {
//   const start = props.entry.start;
//   const end = props.entry.end;
//   const duration = props.entry.durationInMin;
//   const excludes: ("start" | "end")[] = [];
//   const day = props.day;
//   const proxy = {
//     height: 200,
//   };
//
//   if (duration <= 60) {
//     return excludes.includes("start") ? [] : [createTimePos(start, day, 0.5)];
//   } else {
//     let times: TimePosition[] = [];
//
//     if (!excludes.includes("start")) {
//       times.push(createTimePos(start, day, 0));
//     }
//
//     const step = duration / (proxy.height / 30);
//
//     for (
//       // let date = ceilDate(new Date(start + step), 60 * 60 * 1000);
//       let date = ceilDate(new Date(start + step), 60 * 60 * 1000);
//       date <= new Date(end - step);
//       date = new Date(
//         date.getTime() + Math.ceil(step / (60 * 60 * 1000)) * 60 * 60 * 1000
//       )
//     ) {
//       if (date <= new Date(end - step)) {
//         times.push(createTimePos(start, end, day)); // Assuming `from` is a static method in TimePosition class
//       }
//     }
//
//     if (!excludes.includes("end")) {
//       times.push(createTimePos(end, day, 1));
//     }
//
//     return times;
//   }
// };
