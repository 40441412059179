import { type Recurring, type Task } from "@structured/store";
import dayjs, { type Dayjs } from "dayjs";
import { type Duration } from "dayjs/plugin/duration";

export const formatTaskTimeWindow = (
  task: Task | Recurring,
  day: Date
): string => {
  if (task.is_all_day) {
    return `${day.toLocaleDateString()} (All-Day)`;
  }

  if ((task as Task).is_in_inbox) {
    return `${dayjs.duration(task.duration, "minutes").asMinutes()} min`;
  }

  let startTime = dayjs(day).startOf("day");
  let endTime: Dayjs;
  let duration: Duration;

  if (task.localStartTime !== undefined) {
    startTime = startTime.add(task.localStartTime, "hour");
  }

  if (task.duration > 0 && task.localStartTime !== undefined) {
    endTime = startTime.add(task.duration, "minutes");
    duration = dayjs.duration(task.duration, "minutes");

    const now = dayjs();
    if (now.isAfter(startTime) && now.isBefore(endTime)) {
      const remainingMinutes = Math.floor(endTime.diff(now, "minute"));
      if (remainingMinutes >= 60) {
        const hours = Math.floor(remainingMinutes / 60);
        const minutes = remainingMinutes % 60;
        return `${hours} hr${hours === 1 ? "" : "s"}${minutes > 0 ? `, ${minutes} min` : ""} remaining`;
      }
      return `${remainingMinutes} min remaining`;
    }
  }

  let timeWindow = startTime.format("h:mm A");

  if (endTime && duration?.asMinutes() !== 1) {
    timeWindow += ` - ${endTime.format("h:mm A")}`;
  }

  if (duration) {
    const minutes = duration.asMinutes();
    if (minutes !== 1) {
      if (minutes >= 60) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.floor(minutes % 60);
        const hourText = `${hours} hr${hours === 1 ? "" : "s"}`;
        const minuteText = remainingMinutes > 0 ? `, ${remainingMinutes} min` : "";
        timeWindow += ` (${hourText}${minuteText})`;
      } else {
        timeWindow += ` (${minutes} min)`;
      }
    }
  }

  return timeWindow;
};
