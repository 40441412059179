import { Add } from "@mui/icons-material";
import InboxIcon from "@mui/icons-material/Inbox";
import { Button, InputAdornment, TextField, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSession, useTasks, useUI } from "@structured/store";
import { DEFAULT_TASK_SYMBOL_ID } from "@structured/utils/icons";
import React, { useState } from "react";

import { getNewTmpTask } from "../TaskAddModal/getNewTmpTask";
import { getSymbolIdFromTitle } from "../TaskForm/getSymbolIdFromTitle";

interface InboxHeaderProps {
  count: number;
}

export const InboxHeader: React.FC<InboxHeaderProps> = ({ count }) => {
  const { toggleIsDrawerOpen } = useUI();
  const theme = useTheme();
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const { addTask } = useTasks();
  const { user: { id: userId } } = useSession();

  const createInboxTask = () => {
    try {
      if (!newTaskTitle) {
        console.warn("Task title length should at least be 1");
      }
      const newTask = getNewTmpTask();
      const inboxTask = {
        ...newTask,
        title: newTaskTitle,
        is_in_inbox: true,
        day: null,
        symbol: getSymbolIdFromTitle(newTaskTitle ?? DEFAULT_TASK_SYMBOL_ID),
        user_id: userId,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        color: theme.palette.primary.main,
      }
      void addTask(inboxTask);
      setNewTaskTitle("");
    } catch (error) {
      console.error("Error creating inbox task", error);
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newTaskTitle) {
      createInboxTask();
    }
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Box px={4} py={1.5}>
      <Box
        display="flex"
        alignItems="center"
        gap="0.75rem"
        onClick={() => toggleIsDrawerOpen()}
        component={"button"}
        mt={1.25}
      >
        <Box
          color={(theme) => theme.palette.text.secondary}
        >
          <InboxIcon sx={{ fontSize: "1.375rem" }} />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
        >
          <Typography
            variant="h3"
            fontWeight={700}
            fontSize={"1.25rem"}
          >
            Inbox
          </Typography>
          {count > 0 && (
            <Box
              bgcolor={"primary.light"}
              borderRadius={"50%"}
              width={"1.5rem"}
              height={"1.5rem"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              color={"primary.main"}
            >
              <Typography
                fontSize={"0.8rem"}
                fontWeight={400}
              >
                {count}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box my={2.425}>
        <TextField
          value={newTaskTitle}
          onChange={(e) => setNewTaskTitle(e.target.value)}
          fullWidth
          type="text"
          autoFocus
          placeholder="Add a new inbox task..."
          inputRef={inputRef}
          onKeyPress={handleKeyPress}
          InputProps={{
            sx: {
              borderRadius: "0.625rem",
              backgroundColor: "background.paper"
            },
            endAdornment: (
              <>
                <InputAdornment position="end"></InputAdornment>
                <Button
                  onClick={createInboxTask}
                  disabled={!newTaskTitle}
                  aria-label="Add"
                  variant="contained"
                  disableRipple
                  disableElevation
                  sx={{
                    backgroundColor: "primary.main",
                    position: "absolute",
                    right: 0,
                    borderTopRightRadius: "0.625rem",
                    borderBottomRightRadius: "0.625rem",
                    height: "100%",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    "&.Mui-disabled": {
                      bgcolor: "primary.light",
                      color: "primary.main",
                      cursor: "not-allowed",
                    },
                  }}
                >
                  <Add />
                </Button>
              </>
            )
          }}
        />
      </Box >
    </Box >
  );
};
