import type { RxRecurringNaked, RxTaskNaked } from "@structured/utils/rxdb";
import dayjs from "dayjs";
import React, { createContext, useContext, useState } from "react";

import type { Task } from "../task";

interface DrawerState {
  readonly isOpen: boolean;
  readonly width: number;
}

interface UIState {
  selectedDay: Date;
  isShowAddTaskModal: boolean;
  addTaskModalDefaults: Partial<RxTaskNaked> | null;
  drawer: DrawerState;
  contextModalTaskOrRecurring: {
    taskOrRecurring: Task | RxRecurringNaked;
    dayTimestamp: number;
  } | null;
  isSyncing: boolean;
}

interface UIContextProps extends UIState {
  setSelectedDay: (day: number) => void;
  setIsShowAddTaskModal: (isVisible: boolean) => void;
  toggleIsShowAddTaskModal: (defaults?: Partial<RxTaskNaked> | null) => void;
  setIsDrawerOpen: (isOpen: boolean) => void;
  toggleIsDrawerOpen: () => void;
  setIsSyncing: (isSyncing: boolean) => void;
  setContextModalTask: (
    context: {
      taskOrRecurring: Task | RxRecurringNaked;
      dayTimestamp: number;
    } | null
  ) => void;
  setAddTaskModalStartTime: (startTime: number) => void;
}

const initialState: UIState = {
  selectedDay: dayjs(new Date()).startOf("day").toDate(),
  isShowAddTaskModal: false,
  drawer: { isOpen: false, width: 400 },
  contextModalTaskOrRecurring: null,
  addTaskModalDefaults: null,
  isSyncing: false,
};

const UIContext = createContext<UIContextProps>({
  ...initialState,
  setContextModalTask: null,
  setIsDrawerOpen: null,
  setIsShowAddTaskModal: null,
  setIsSyncing: null,
  setSelectedDay: null,
  toggleIsDrawerOpen: null,
  toggleIsShowAddTaskModal: null,
  addTaskModalDefaults: null,
  setAddTaskModalStartTime: null,
});

export const UIProvider: React.FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setSelectedDay = (day: number) => {
    setState((prevState) => ({
      ...prevState,
      selectedDay: dayjs(day).startOf("day").toDate(),
    }));
  };

  const setIsShowAddTaskModal = (isVisible: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isShowAddTaskModal: isVisible,
    }));
  };

  const toggleIsShowAddTaskModal = (defaults?: Partial<RxTaskNaked> | null) => {
    setState((prevState) => ({
      ...prevState,
      isShowAddTaskModal: !prevState.isShowAddTaskModal,
      addTaskModalDefaults: defaults ?? null,
    }));
  };

  const setIsDrawerOpen = (isOpen: boolean) => {
    setState((prevState) => ({
      ...prevState,
      drawer: { ...prevState.drawer, isOpen },
    }));
  };

  const toggleIsDrawerOpen = () => {
    setState((prevState) => ({
      ...prevState,
      drawer: { ...prevState.drawer, isOpen: !prevState.drawer.isOpen },
    }));
  };

  const setIsSyncing = (isSyncing: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isSyncing,
    }));
  };

  const setContextModalTask = (
    context: {
      taskOrRecurring: Task | RxRecurringNaked;
      dayTimestamp: number;
    } | null
  ) => {
    setState((prevState) => ({
      ...prevState,
      contextModalTaskOrRecurring: context,
    }));
  };

  const setAddTaskModalStartTime = (startTime: number) => {
    setState((prevState) => ({
      ...prevState,
      addTaskModalDefaults: {
        ...prevState.addTaskModalDefaults,
        start_time: startTime,
      },
    }));
  };

  return (
    <UIContext.Provider
      value={{
        ...state,
        setSelectedDay,
        setIsShowAddTaskModal,
        toggleIsShowAddTaskModal,
        setIsDrawerOpen,
        toggleIsDrawerOpen,
        setIsSyncing,
        setContextModalTask,
        setAddTaskModalStartTime,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => useContext(UIContext);
