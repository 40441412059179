import { Discount, PhoneIphone } from "@mui/icons-material";
import RepeatIcon from "@mui/icons-material/Repeat";
import { Box, Button, Link, Skeleton, Typography, useTheme } from "@mui/material";
import { initializePaddle, type Paddle, type PricePreviewParams, type PricePreviewResponse, type TimePeriod } from "@paddle/paddle-js";
import { useSession, useSettings } from "@structured/store";
import dimPrimaryBackground from "@structured/utils/colors/functions/dimPrimaryBackground";
import getBackgroundColorWithOpacity from "@structured/utils/colors/functions/getBackgroundColorWithOpacity";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIsPro } from "src/redux/slices/isProSlice";
import { type AppDispatch, type RootState } from "src/redux/store";

export default function SubSettingSubscription(): JSX.Element {
    const theme = useTheme();
    const { settings } = useSettings();
    const appUserId = settings.analytics_id;
    const dispatch = useDispatch<AppDispatch>();
    const { isPro, loading: isProLoading, customerInfo } = useSelector((state: RootState) => state.isPro);
    const session = useSession();
    const [paddle, setPaddle] = useState<Paddle | undefined>();
    const [isPriceLoading, setIsPriceLoading] = useState<boolean>(true);
    const [pricePreview, setPricePreview] = useState<PricePreviewResponse | null>(null);
    const [store, setStore] = useState<string | null>(null);
    const [managePlanURL, setManagePlanURL] = useState<string | null>(null);
    const getGeoInfo = async () => {
        try {
            const url = "https://ipapi.co/json/";
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("API request failed");
            }
            const data: { country: string } = await response.json();
            return data.country;
        } catch (error) {
            console.error("Error fetching your geo info, try disabling your adblocker");
            return "US";
        }
    };

    const getPrices = async (): Promise<void> => {
        if (isPro) return;
        setIsPriceLoading(true);
        const country = await getGeoInfo();
        const request: PricePreviewParams = {
            items: [
                {
                    quantity: 1,
                    priceId: process.env.REACT_APP_PADDLE_MONTHLY
                },
                {
                    quantity: 1,
                    priceId: process.env.REACT_APP_PADDLE_YEARLY
                },
                {
                    quantity: 1,
                    priceId: process.env.REACT_APP_PADDLE_LIFETIME
                },
            ],
            discountId: process.env.REACT_APP_PADDLE_DISCOUNT,
            address: {
                countryCode: country
            }
        }

        await paddle?.PricePreview(request)
            .then((result) => {
                setPricePreview(result);
                setIsPriceLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsPriceLoading(false);
            });
    }

    useEffect(() => {
        void dispatch(fetchIsPro(appUserId.toUpperCase()));
        const setPaymentStoreSource = () => {
            if (!isPro || !customerInfo) return;
            const store = (customerInfo.entitlements.active.pro.store)
            if (String(store) === "external") {
                setStore("Paddle");
                setManagePlanURL("https://strct.app/customer-portal");
            } else if (String(store) === "app_store") {
                setStore("Apple App Store");
                setManagePlanURL("https://help.structured.app/en/articles/354178");
            } else if (String(store) === "play_store") {
                setStore("Google Play Store");
                setManagePlanURL("https://help.structured.app/en/articles/354178");
            } else {
                setStore(null);
            }
        };
        if (customerInfo) {
            setPaymentStoreSource();
        }
    }, [dispatch, settings.analytics_id]);

    useEffect(() => {
        const initPaddle = async (): Promise<void> => {
            try {
                const paddleInstance = await initializePaddle({
                    environment: process.env.REACT_APP_PAYMENTS_ENVIRONMENT === "sandbox" ? "sandbox" : "production",
                    token: process.env.REACT_APP_PADDLE_KEY,
                });
                setPaddle(paddleInstance);
            } catch (error) {
                console.error("Error initializing Paddle:", error);
            }
        };
        void initPaddle();
    }, []);

    useEffect(() => {
        if (paddle) {
            void getPrices();
        }
    }, [paddle, isPro]);

    const openCheckout = (priceId: string): void => {
        paddle?.Checkout.open({
            settings: {
                displayMode: "overlay",
                allowLogout: false,
                successUrl: `${window.location.origin}/success`,
            },
            discountId: process.env.REACT_APP_PADDLE_DISCOUNT,
            items: [{ priceId }],
            customer: { email: session.user.email },
            customData: { userId: settings.analytics_id.toUpperCase() },
        });
    };

    const getPriceLabel = (billingCycle: TimePeriod | null, amount: string): string => {
        if (!billingCycle) {
            return `${amount} once`;
        }
        switch (billingCycle.interval) {
            case "month":
                return `${amount} / month`;
            case "year":
                return `${amount} / year`;
            default:
                return "";
        }
    }

    const getPriceDescription = (billingCycle: TimePeriod | null, trialPeriod: TimePeriod | null, amount: number, formattedTotal: string): string => {
        const currencySymbol = formattedTotal.match(/[^0-9.,\s]/)?.[0] || "";
        if (trialPeriod) {
            if (trialPeriod.interval === "day") {
                if (trialPeriod.frequency === 3) {
                    return "Try three days for free.";
                }
                return `Try ${trialPeriod.frequency} days for free`;
            }
        }
        if (!billingCycle) {
            return "Buy Once.\nKeep Forever.";
        }

        switch (billingCycle.interval) {
            case "month":
                return "Most flexible.\n Try it now.";
            case "year":
                return `Best Value!\nOnly ${currencySymbol}${(amount / 12).toFixed(2)} / month.`;
            default:
                return "";
        }
    }

    const getCTA = (billingCycle: TimePeriod | null, trialPeriod: TimePeriod | null): string => {
        if (trialPeriod) {
            return "Start Free Trial";
        }
        if (!billingCycle) {
            return "Buy Once";
        }
        return "Subscribe";
    }

    if (isPro) {
        return (
            <Box display={"flex"} flexDirection={"column"} gap={"1.5rem"} padding={"1.5rem 0rem 0rem"}>
                <Typography
                    fontSize={"2rem"}
                    fontWeight={500}
                >
                    Thanks for using
                    <Box
                        component="span"
                        fontWeight={700}
                        sx={{
                            background: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                            backgroundClip: "text",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}
                    >
                        {" "}Structured Pro!
                    </Box>
                </Typography>
                <Typography
                    fontSize={"1rem"}
                    fontWeight={500}
                >
                    You can access all Structured features on iOS, Mac and Android.
                </Typography>
                {store && (
                    <Box display={"flex"} flexDirection={"row"} gap={"1rem"} alignItems={"center"}>
                        <Link href={managePlanURL} target="_blank" rel="noopener noreferrer">
                            <Button
                                sx={{
                                    padding: "6px 12px",
                                    background: getBackgroundColorWithOpacity(theme.palette.primary.light, 0.25),
                                    "&.MuiButtonBase-root:hover": {
                                        bgcolor: getBackgroundColorWithOpacity(theme.palette.primary.light, 0.30)
                                    }
                                }}
                                variant="contained"
                                disableElevation
                            >
                                <Typography
                                    color={theme.palette.primary.main}
                                    fontWeight={500}
                                >
                                    Manage Plan
                                </Typography>
                            </Button>
                        </Link>
                        {store !== "Paddle" && (
                            <Typography>
                                You purchased Structured Pro on the {store}.
                            </Typography>
                        )}
                    </Box>
                )}
            </Box >
        )
    }

    return (
        <>
            <Box>
                <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        gap={"1.5rem"}
                    >
                        <Typography
                            fontSize={"2rem"}
                            fontWeight={500}
                        >
                            Upgrade To
                        </Typography>
                        <Typography
                            fontSize={"2rem"}
                            fontWeight={700}
                            sx={{
                                background: "linear-gradient(180deg, #FFB8B2 0%, #F49F99 100%)",
                                backgroundClip: "text",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",

                            }}
                        >
                            Structured Pro
                        </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} gap={"0.75rem"} textAlign={"left"}>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.75rem"}
                        >
                            <RepeatIcon sx={{ width: "20px", height: "20px", }} color="primary" />
                            <Typography
                                fontWeight={500}
                            >
                                Create Recurring Tasks
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.75rem"}
                        >
                            <PhoneIphone sx={{ width: "20px", height: "20px", }} color="primary" />
                            <Typography
                                fontWeight={500}
                            >
                                Access on iOS, Mac & Android
                            </Typography>
                        </Box>
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"0.75rem"}
                        >
                            <Discount sx={{ width: "20px", height: "20px", }} color="primary" />
                            <Typography
                                fontWeight={500}
                            >
                                Special Beta Discount - 33% OFF
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"1rem"}
                    mt={"1.75rem"}
                >
                    {
                        !isPriceLoading && pricePreview ? (
                            pricePreview.data.details.lineItems.map((item) => (
                                <Box
                                    key={item.price.id}
                                    display="flex"
                                    padding={"0.75rem"}
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={"1rem"}
                                    borderRadius={"1.25rem"}
                                    border={`1px solid ${theme.palette.text.disabled}`}
                                    width={"100%"}
                                    height={"13rem"}
                                >
                                    <Box display="flex" flexDirection="column" justifyContent={"center"} height={"100%"} width={"100%"}>
                                        <Box display={"flex"} flexDirection={"column"} justifyItems={"center"} gap={"1rem"}>
                                            <Typography
                                                color={theme.palette.text.primary}
                                                fontSize={"1.5rem"}
                                                fontWeight={500}
                                            >
                                                {item.price.name}
                                            </Typography>
                                            <Typography
                                                color={theme.palette.primary.main}
                                                fontSize={"1.25rem"}
                                                fontWeight={500}
                                            >
                                                {getPriceLabel(item.price.billingCycle, item.formattedTotals.total)}
                                            </Typography>
                                            <Typography
                                                color="text.secondary"
                                                sx={{ whiteSpace: "pre-line", }}
                                            >
                                                {getPriceDescription(
                                                    item.price.billingCycle,
                                                    item.price.trialPeriod,
                                                    (Number(item.totals.total) / 100),
                                                    item.formattedTotals.total
                                                )}
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{
                                                mt: "auto",
                                                "&:hover": {
                                                    backgroundColor: dimPrimaryBackground,
                                                },
                                            }}
                                            onClick={() => openCheckout(item.price.id)}
                                            disableElevation
                                        >
                                            {getCTA(item.price.billingCycle, item.price.trialPeriod)}
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            Array.from({ length: 3 }).map((_, index) => (
                                <Box
                                    key={index}
                                    display="flex"
                                    padding={"0.75rem"}
                                    flexDirection="column"
                                    alignItems="center"
                                    gap={"1rem"}
                                    borderRadius={"1.25rem"}
                                    border={`1px solid ${theme.palette.text.disabled}`}
                                    width={"100%"}
                                    height={"13rem"}
                                >
                                    <Box display="flex" flexDirection="column" justifyContent={"center"} height={"100%"} width={"100%"}>
                                        <Box display={"flex"} flexDirection={"column"} justifyItems={"center"} gap={"1rem"}>
                                            <Typography
                                                color={theme.palette.text.primary}
                                                fontSize={"1.5rem"}
                                                fontWeight={500}
                                            >
                                                <Skeleton variant="text" width="60%" height={40} />
                                            </Typography>
                                            <Typography
                                                color={theme.palette.primary.main}
                                                fontSize={"1.25rem"}
                                                fontWeight={500}
                                            >
                                                <Skeleton variant="text" width="40%" height={60} />
                                            </Typography>
                                            <Typography
                                                color="text.secondary"
                                                sx={{ whiteSpace: "pre-line", }}
                                            >
                                                <Skeleton variant="text" width="80%" height={20} />
                                            </Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{ mt: "auto" }}
                                            disableElevation
                                            disabled
                                        >
                                            <Skeleton variant="text" width="70%" height={20} />
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                        )
                    }
                </Box >
            </Box>
        </>
    );
}
