import { Add, Inbox } from "@mui/icons-material";
import { Button, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { type Task, useSession, useTasks, useUI } from "@structured/store";
import { DEFAULT_TASK_SYMBOL_ID } from "@structured/utils/icons";
import React, { useState } from "react";

import { getNewTmpTask } from "../TaskAddModal/getNewTmpTask";
import { getSymbolIdFromTitle } from "../TaskForm/getSymbolIdFromTitle";
import { InboxTask } from "./InboxTask/InboxTask";

export const InboxTasks: React.FC = () => {
  const theme = useTheme();
  const { selectedDay } = useUI();
  const { getInboxTasks } = useTasks();
  const incompleteInboxTasks = getInboxTasks("incomplete");
  const completeInboxTasks = getInboxTasks("complete");

  const sortTasks = (tasks: Task[]) => {
    return tasks.sort((a, b) => {
      if (a.order_index !== b.order_index) {
        return a.order_index - b.order_index;
      }
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });

  };

  const sortedIncompleteTasks = sortTasks(incompleteInboxTasks);
  const sortedCompleteTasks = sortTasks(completeInboxTasks);
  const [newTaskTitle, setNewTaskTitle] = useState("");
  const { addTask } = useTasks();
  const { user: { id: userId } } = useSession();
  const createInboxTask = () => {
    try {

      if (!newTaskTitle) {
        console.warn("Task title length should at least be 1");

      }
      const newTask = getNewTmpTask();

      const inboxTask = {
        ...newTask,
        title: newTaskTitle,
        is_in_inbox: true,
        day: null,
        symbol: getSymbolIdFromTitle(newTaskTitle ?? DEFAULT_TASK_SYMBOL_ID),
        user_id: userId,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        color: theme.palette.primary.main,
      }

      void addTask(inboxTask);
      setNewTaskTitle("");
    } catch (error) {
      console.error("Error creating inbox task", error);
    }


  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && newTaskTitle) {
      createInboxTask();
    }
  };

  const hasNoTasks = sortedIncompleteTasks.length === 0 && sortedCompleteTasks.length === 0;

  const inputRef = React.useRef<HTMLInputElement>(null);

  const focusInput = () => {
    inputRef.current?.focus();
  };

  return (
    <Box>
      <Box padding="0rem 2rem" pb={8} height="100%">
        {hasNoTasks ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "70%",
              mt: 16,
              cursor: "pointer",
            }}
            onClick={focusInput}
          >
            <Box
              width="4.5rem"
              height="4.5rem"
              borderRadius="50%"
              bgcolor="primary.light"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Inbox sx={{ color: "primary.main", fontSize: "2rem" }} />
            </Box>
            <Box px={2}>
              <Typography
                fontSize="1.125rem"
                fontWeight={600}
                textAlign="center"
                mb={1}
              >
                Your Unstructured Thoughts
              </Typography>
              <Typography
                color="text.secondary"
                textAlign={"center"}
                fontSize="0.75rem"
                fontWeight={400}
                lineHeight={1.5}
              >
                Capture tasks and thoughts as they come.
                <br />
                Move them to your timeline when you're ready to schedule.
              </Typography>
            </Box>
          </Box >
        ) : (
          [...sortedIncompleteTasks, ...sortedCompleteTasks].map((task) => (
            <InboxTask key={task.id} task={task} day={selectedDay} />
          ))
        )}
      </Box >
    </Box >
  );
};
