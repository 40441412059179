import Box from "@mui/material/Box";
import { useOccurrences } from "@structured/store";
import { useDailyRecurringTasks, useDailyTasks } from "@structured/utils/hooks";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import { useEffect } from "react";

import { type RxTaskNaked } from "../../utils/rxdb/schemas/task.schema";
import { AllDayTask } from "./AllDayTask/AllDayTask";

const DEFAULT_ORDER_INDEX = 0;

export const AllDayTasks = (props: { day: Date, setAllDayTasksLength: (length: number) => void }) => {
  const { occurrences } = useOccurrences();
  const dailyTasks = useDailyTasks(props.day);
  const dailyRecurringTasks = useDailyRecurringTasks(props.day);
  const getLocalIndex = (task: RxRecurringNaked) => {
    for (const i of occurrences) {
      if (i.recurring === task.id) {
        return i.order_index;
      }
    }
  }
  const allDayTasks = [...dailyTasks, ...dailyRecurringTasks]
    .filter((t) => t.is_all_day && !(t as RxTaskNaked).is_in_inbox)
    .sort((a, b) => {
      const aOI = (a as RxTaskNaked).order_index ?? getLocalIndex(a as RxRecurringNaked) ?? DEFAULT_ORDER_INDEX;
      const bOI = (b as RxTaskNaked).order_index ?? getLocalIndex(b as RxRecurringNaked) ?? DEFAULT_ORDER_INDEX;
      if (aOI !== bOI) {
        return aOI - bOI;
      } else {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ); 
      }
    });


  useEffect(() => {
    props.setAllDayTasksLength(allDayTasks.length);
  }, [allDayTasks.length, props.setAllDayTasksLength]);

  return allDayTasks.length > 0 ? (
    <Box display={"flex"} py={"1rem"} className="space-x-5">
      {allDayTasks.map((t) => (<AllDayTask taskOrRecurring={t} key={t.id} day={props.day} />))}
    </Box>
  ) : (
    <></>
  );
};
