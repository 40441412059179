import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { Box, Button, Typography } from "@mui/material";
import dimPrimaryBackground from "@structured/utils/colors/functions/dimPrimaryBackground";
import {
    type TmpTask,
} from "@structured/utils/tasks";
import { type Control, Controller, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TaskTypeInputRowProps {
    disabled: boolean;
    task: TmpTask;
    setValue: UseFormSetValue<FormValues>;
    control: Control<FormValues>;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}
export const TaskTypeInputRow = (props: TaskTypeInputRowProps) => {
    const handleTaskTypeChange = (isInInbox: boolean) => {
        props.setValue("task.is_in_inbox", isInInbox);
        props.setValue("task", { ...props.task, is_in_inbox: isInInbox });
        props.onUpdateTmpTask({ ...props.task, is_in_inbox: isInInbox });
    };

    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <WidgetsOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    width: "31.3125rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Controller
                    control={props.control}
                    name="task.is_in_inbox"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <Button
                            onClick={() => handleTaskTypeChange(false)}
                            {...field}
                            fullWidth
                            disableElevation
                            variant={!value ? "contained" : "outlined"}
                            endIcon={<InboxOutlinedIcon />}
                            size="large"
                            sx={{
                                height: "3.125rem",
                                "&:hover": {
                                    backgroundColor: !value && dimPrimaryBackground,
                                },
                            }}
                        >
                            <Typography
                                fontSize="1rem"
                                fontWeight="500"
                            >
                                Planned
                            </Typography>
                        </Button>
                    )}
                />
                <Controller
                    control={props.control}
                    name="task.is_in_inbox"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <Button
                            onClick={() => handleTaskTypeChange(true)}
                            {...field}
                            fullWidth
                            disableElevation
                            variant={value ? "contained" : "outlined"}
                            endIcon={<InboxOutlinedIcon />}
                            size="large"
                            sx={{
                                height: "3.125rem",
                                "&:hover": {
                                    backgroundColor: value && dimPrimaryBackground,
                                },
                            }}
                        >
                            <Typography
                                fontSize="1rem"
                                fontWeight="500"
                            >
                                Inbox
                            </Typography>
                        </Button>
                    )}
                />

            </Box>
        </TaskFormRowContainer >
    );
}
