import {
  type Task,
  useOccurrences,
  useRecurringTasks,
  useTasks,
} from "@structured/store";
import { dirtyDeepCopy } from "@structured/utils/common";
import { getIsoStrDateOnly } from "@structured/utils/date";
import { useToggleCompletedAt } from "@structured/utils/hooks";
import {
  type RxRecurringNaked,
  type RxTaskNaked,
  TMP_REV,
} from "@structured/utils/rxdb";
import {
  areRecurringTasksEqual,
  getRecurringOnlyFields,
  getSharedRecurringFieldsForTmpTask,
  getTaskOnlyFieldsFromRecurring,
  isRecurringTask,
  type RecurringOwnFields,
  RecurringType,
  type TmpSubtask,
  type TmpTask,
} from "@structured/utils/tasks";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

// TODO: refactor this hook, maybe join it with `useTask` hook
export const useTaskEditModal = ({
  taskDay,
  taskOrRecurring,
  onSubmit,
}: {
  taskDay: Date;
  taskOrRecurring: Task | RxRecurringNaked;
  onSubmit: () => void;
}) => {
  const {
    updateOccurrence,
    addOccurrence,
    removeOccurrences,
    getDailyOccurrenceForRecurringTask,
    getOccurrencesForRecurringTask,
  } = useOccurrences();

  const { updateTask: updateContextTask, addTask, removeTask } = useTasks();
  const { updateRecurring, addRecurring, removeRecurring } =
    useRecurringTasks();
  const isRecurring = isRecurringTask(taskOrRecurring);
  const isCalendarTask =
    !isRecurring && (taskOrRecurring as Task).events?.length > 0;

  const userId = taskOrRecurring.user_id;
  const recurringTaskOccurrences = getOccurrencesForRecurringTask(
    taskOrRecurring.id
  );

  const dailyOccurrence = getDailyOccurrenceForRecurringTask(
    taskOrRecurring.id,
    taskDay
  );

  const defaultCompletedAt =
    dailyOccurrence?.completed_at ??
    (taskOrRecurring as RxTaskNaked).completed_at ??
    null;

  const [completedAt, setCompletedAt] = useState(defaultCompletedAt);

  const [newRecurringOwnFields, setNewRecurringOwnFields] =
    useState<RecurringOwnFields | null>(
      isRecurring
        ? getRecurringOnlyFields(taskOrRecurring as RxRecurringNaked)
        : null
    );

  const [showRecurringUpdatePromptModal, setIsShowRecurringUpdateModal] =
    useState(false);

  const [showDayChangedWarning, setShowDayChangedWarning] = useState(false);

  const [tmpTask, setTmpTask] = useState<TmpTask>(
    isRecurring
      ? getTaskOnlyFieldsFromRecurring(
        taskOrRecurring,
        dayjs(taskDay).format("YYYY-MM-DD")
      )
      : (dirtyDeepCopy(taskOrRecurring) as RxTaskNaked)
  );

  useEffect(() => {
    setTmpTask((val) => ({ ...val, completed_at: completedAt }));
  }, [completedAt]);

  const { toggleCompletedAt } = useToggleCompletedAt(
    taskDay,
    isRecurring ? taskOrRecurring : (tmpTask as Task),
    dailyOccurrence
  );

  const [tmpSubtasks, setTmpSubtasks] = useState<TmpSubtask[]>(
    // TODO make it work for recurring
    dirtyDeepCopy((taskOrRecurring.subtasks as TmpSubtask[]) ?? [])
  );

  const updateTmpTask = (updateProps: Partial<TmpTask>) => {
    setTmpTask((val) => ({
      ...val,
      ...updateProps,
    }));
  };

  const updateCompletedAt = () => {
    void toggleCompletedAt();
    setCompletedAt((value) => (value ? null : new Date().toISOString()));
  };

  const updateAllRecurring = () => {
    if (!newRecurringOwnFields) {
      throw new Error("No updatedRecurringCfg");
    }

    void updateRecurring(tmpTask.id, {
      ...newRecurringOwnFields,
      ...getSharedRecurringFieldsForTmpTask(tmpTask),
      subtasks: tmpSubtasks,
      modified_at: new Date().toISOString(),
    });
    onSubmit();
  };


  const convertTaskToRecurring = (startTime: number, preserveTask: boolean, timezone: string | null = null) => {
    if (!newRecurringOwnFields) {
      throw new Error("No updatedRecurringCfg");
    }

    const recurringId = uuid();
    const actions: Promise<void>[] = [];

    actions.push(removeTask(tmpTask.id));

    actions.push(addRecurring({
      ...getSharedRecurringFieldsForTmpTask(tmpTask),
      ...newRecurringOwnFields,
      subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
      user_id: userId,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      replication_revision: TMP_REV,
      id: recurringId,
      start_time: startTime,
      timezone
    }));

    if (preserveTask) {
      actions.push(addOccurrence({
        id: uuid(),
        user_id: userId,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        recurring: recurringId,
        day: getIsoStrDateOnly(taskDay),
        replication_revision: TMP_REV,
        order_index: tmpTask.order_index,
        completed_at: new Date().toISOString(),
        detached_task: null,
        is_detached: false,
        _deleted: false,
        subtask_occurrences: tmpSubtasks.map(subtask => ({
          id: uuid(),
          subtask: subtask.id,
          completed_at: new Date().toISOString()
        })),
      }));
    }

    void Promise.all(actions);
  };


  const convertRecurringToTask = () => {
    void removeOccurrences(recurringTaskOccurrences.map((occ) => occ.id));

    void removeRecurring(taskOrRecurring.id);
    void addTask({
      ...getTaskOnlyFieldsFromRecurring(
        {
          ...taskOrRecurring,
          ...tmpTask,
        },
        dayjs(taskDay).format("YYYY-MM-DD")
      ),
      subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
      user_id: userId,
      created_at: new Date().toISOString(),
      modified_at: new Date().toISOString(),
      replication_revision: TMP_REV,
      id: uuid(),
    });
  };

  const completeSubtask = (subtaskId: string, completed: boolean) => {
    const updatedSubtasks = tmpSubtasks.map((subtask) => {
      return subtask.id === subtaskId
        ? {
          ...subtask,
          completed_at: completed ? new Date().toISOString() : null,
        }
        : subtask;
    });

    if (isRecurring) {
      const subtaskOccurrences = updatedSubtasks.map((subtask) => ({
        id: uuid(),
        subtask: subtask.id,
        completed_at: subtask.completed_at,
      }));

      if (dailyOccurrence) {
        void updateOccurrence(dailyOccurrence.id, {
          subtask_occurrences: subtaskOccurrences,
        });
      } else {
        void addOccurrence({
          id: uuid(),
          user_id: taskOrRecurring.user_id,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          recurring: taskOrRecurring.id,
          day: getIsoStrDateOnly(taskDay),
          replication_revision: TMP_REV,
          order_index: null,
          completed_at: null,
          detached_task: null,
          is_detached: false,
          _deleted: false,
          subtask_occurrences: subtaskOccurrences,
        });
      }
    } else {
      void updateContextTask(taskOrRecurring.id, { subtasks: updatedSubtasks });
    }
  };

  const afterTaskEditSubmit = (startTime: number, preserveTask = true, timezone: string | null = null) => {
    if (!tmpTask.id) {
      throw new Error(
        "Tmp taskOrRecurring key is needed to created taskOrRecurring with sub tasks"
      );
    }
    if (tmpTask.title.length < 1) {
      console.warn("Task title length should at least be 1");
      // TODO inform user somehow
      return;
    }

    if (isRecurring) {
      if (newRecurringOwnFields === null) {
        convertRecurringToTask();
        onSubmit();
      } else if (tmpTask.is_in_inbox) {
        updateSingleRecurringInstance();
        onSubmit();
      } else if (
        newRecurringOwnFields.start_day !==
        (taskOrRecurring as RxRecurringNaked).start_day
      ) {
        setShowDayChangedWarning(true);
      } else if (
        !areRecurringTasksEqual(
          getRecurringOnlyFields(taskOrRecurring as RxRecurringNaked),
          newRecurringOwnFields
        )
      ) {
        updateAllRecurring();
        onSubmit();
      } else {
        setIsShowRecurringUpdateModal(true);
      }
    } else if (
      !isRecurring &&
      newRecurringOwnFields &&
      newRecurringOwnFields.recurring_type !== null
    ) {
      convertTaskToRecurring(startTime, preserveTask, timezone);
      onSubmit();
    } else {
      updateTask(startTime, timezone);
      onSubmit();
    }
  };

  const updateTask = (startTime: number, timezone: string | null = null) => {
    void updateContextTask(tmpTask.id, {
      ...tmpTask,
      subtasks: tmpSubtasks,
      modified_at: new Date().toISOString(),
      day: tmpTask.day ?? dayjs(taskDay).format("YYYY-MM-DD"),
      start_time: tmpTask.start_time ?? startTime,
      timezone,
    });
  };

  const updateSingleRecurringInstance = () => {
    const taskDate = dayjs(taskDay).format("YYYY-MM-DD");

    const newTaskId = uuid();
    const payload = {
      ...tmpTask,
      subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
    };
    const actions: Promise<void>[] = [];

    actions.push(
      addTask({
        ...payload,
        id: newTaskId,
        user_id: taskOrRecurring.user_id,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
      })
    );

    if (dailyOccurrence) {
      actions.push(
        updateOccurrence(dailyOccurrence.id, {
          is_detached: true,
          detached_task: newTaskId,
        })
      );
    } else {
      if (!newRecurringOwnFields) {
        throw new Error("No updatedRecurringCfg");
      }
      actions.push(
        addOccurrence({
          id: uuid(),
          user_id: taskOrRecurring.user_id,
          order_index: payload.order_index,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          recurring: payload.id,
          day: taskDate,
          subtask_occurrences: [],
          replication_revision: TMP_REV,
          completed_at: payload.completed_at ?? null,
          detached_task: newTaskId,
          is_detached: true,
          _deleted: false,
        })
      );
    }

    void Promise.all(actions);

    onSubmit();
  };

  const confirmDayChange = () => {
    updateSingleRecurringInstance();
    onSubmit();
  };

  const updateFutureRecurring = () => {
    const actions: Promise<void>[] = [];

    const payload = {
      ...tmpTask,
      subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
    };

    if (!newRecurringOwnFields) {
      throw new Error("No recurringCfg");
    }

    let nextStartDay = dayjs(taskDay);
    if (newRecurringOwnFields.recurring_type === RecurringType.Monthly) {
      nextStartDay = nextStartDay.add(newRecurringOwnFields.interval, "month");
    }

    const dayBeforeDate = nextStartDay.subtract(1, "day");

    const taskOrRecurringCopy = {
      ...taskOrRecurring,
      completed_at: undefined,
    }
    delete taskOrRecurringCopy.completed_at;
    actions.push(
      updateRecurring(taskOrRecurringCopy.id, {
        end_day: dayBeforeDate.format("YYYY-MM-DD"),
      })
    );

    const newRecurringId = uuid();

    actions.push(
      addRecurring({
        ...taskOrRecurringCopy,
        ...getSharedRecurringFieldsForTmpTask(payload),
        ...newRecurringOwnFields,
        id: newRecurringId,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        start_day: nextStartDay.format("YYYY-MM-DD"),
      })
    );

    const occurrences = recurringTaskOccurrences.filter(
      (occurrence) => new Date(occurrence.day) >= taskDay
    );

    occurrences.forEach((occurrence) => {
      actions.push(
        updateOccurrence(occurrence.id, {
          recurring: newRecurringId,
        })
      );
    });

    void Promise.all(actions);

    onSubmit();
  };

  return {
    showRecurringUpdatePromptModal,
    showDayChangedWarning,
    isCalendarTask,
    newRecurringOwnFields,
    tmpTask,
    tmpSubtasks,
    completedAt,
    confirmDayChange,
    setShowDayChangedWarning,
    setIsShowRecurringUpdateModal,
    updateTmpTask,
    afterTaskEditSubmit,
    updateSingleRecurringInstance,
    updateFutureRecurring,
    updateAllRecurring,
    setTmpSubtasks,
    setNewRecurringOwnFields,
    updateCompletedAt,
    completeSubtask,
  };
};
