import AddIcon from "@mui/icons-material/Add";
import { Drawer, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import emptyStateImage from "@structured/assets/emptyState.webp";
import { Content } from "@structured/components/Content";
import { useSettings, useTasks, useUI } from "@structured/store";
import dimPrimaryBackground from "@structured/utils/colors/functions/dimPrimaryBackground";
import { useDailyTimelineEntries } from "@structured/utils/hooks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchIsPro } from "src/redux/slices/isProSlice";
import { type AppDispatch } from "src/redux/store";

import { AllDayTasks } from "../AllDayTasks/AllDayTasks";
import { GlobalModals } from "../GlobalModals/GlobalModals";
import { InboxHeader } from "../InboxTasks/InboxHeader";
import { InboxTasks } from "../InboxTasks/InboxTasks";
import { InboxDrawer } from "./InboxDrawer";
import { SelectDayBar } from "./SelectDayBar/SelectDayBar";
import { Timeline } from "./Timeline/Timeline";

export const DayView: React.FC = () => {
  const { selectedDay, setSelectedDay, toggleIsShowAddTaskModal } = useUI();
  const dispatch = useDispatch<AppDispatch>();
  const { settings } = useSettings();
  const { getInboxTasks } = useTasks();

  useEffect(() => {
    const appUserId = settings.analytics_id;
    const processProStatus = async () => {
      await dispatch(fetchIsPro(appUserId.toUpperCase()));
    }
    void processProStatus();
  }, [dispatch, settings.analytics_id]);
  const getInboxTasksCount = () => {
    const tasks = getInboxTasks("all");
    let count = 0;
    count = tasks.filter(task => !task.completed_at).length;
    return count;
  }
  const [allDayTasksLength, setAllDayTasksLength] = React.useState(0);
  const timelineEntries = useDailyTimelineEntries(new Date(selectedDay), new Date());

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <GlobalModals />
      <InboxDrawer />
      <Box sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: "background.default"
      }}>
        <Content>
          <SelectDayBar
            selectedDay={selectedDay}
            onDaySelect={(date) => setSelectedDay(date.getTime())}
          />
          <Divider />
        </Content>
      </Box>
      <Box sx={{
        flex: 1,
        overflow: "auto",
        paddingTop: "1rem"
      }}>
        <Content paddingX="3.5rem" paddingBottom="2rem">
          <Box maxWidth="80rem" margin="auto">
            {(timelineEntries.length === 0 && allDayTasksLength === 0) ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="calc(100vh - 18.75rem)"
                gap={1}
              >
                <div className="hidden"><AllDayTasks day={selectedDay} setAllDayTasksLength={setAllDayTasksLength} /></div>
                <img
                  src={emptyStateImage}
                  alt="No tasks"
                  style={{ maxWidth: "100%", height: "auto", width: "15.4375rem" }}
                />
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                >
                  Plan your day and add a task to get started
                </Typography>
                <Typography color={"text.secondary"}>
                  Make your first move towards a productive day.
                </Typography>
              </Box>
            ) : (
              <Box sx={{ maxWidth: "65rem", margin: "auto" }}>
                <Box px={5.5}>
                  <AllDayTasks day={selectedDay} setAllDayTasksLength={setAllDayTasksLength} />
                  <Box
                    border={"1px dashed"}
                    borderColor={"#C4C4C6"}
                    display={allDayTasksLength > 0 ? "block" : "none"}
                  />
                </Box>
                <Box>
                  <Timeline day={selectedDay} />
                </Box>
              </Box>
            )}
          </Box>
        </Content>
      </Box>

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: "2.5rem",
          right: "3rem",
          "&:hover": {
            backgroundColor: dimPrimaryBackground,
            transition: "0.2s",
          }
        }}
        onClick={() => toggleIsShowAddTaskModal(null)}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};