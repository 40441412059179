import CloseIcon from "@mui/icons-material/Close";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Button, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ModalWrapper } from "@structured/components";
import { useSettings } from "@structured/store";
import { type ThemeMode } from "@structured/utils/colors";
import { type RxSettingsEntryNaked } from "@structured/utils/rxdb";
import { useState } from "react";

import styles from "./SettingsCmp.module.scss";
import { SubSettingAdvanced } from "./SubSettings/SubSettingAdvanced";
import { SubSettingAppearance } from "./SubSettings/SubSettingAppearance";
import SubSettingSubscription from "./SubSettings/SubSettingSubscription";
import { SubSettingSync } from "./SubSettings/SubSettingSync";

interface SettingsButtonElement extends HTMLButtonElement {
  openWithTab?: TabValue;
}

enum TabValue {
  CloudSettings = "Cloud Settings",
  Appearance = "Appearance",
  Advanced = "Advanced",
  Subscription = "Structured Pro",
}

export const SettingsCmp = (props: { triggerResync: () => void }) => {
  const { settings, themeMode, updateSettings, updateThemeMode } =
    useSettings();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [debugMenuClickCount, setDebugMenuClickCount] = useState<number>(0);
  const [tab, setTab] = useState<TabValue>(TabValue.CloudSettings);

  const handleSettingsUpdate = (upd: Partial<RxSettingsEntryNaked>) => {
    void updateSettings(settings.user_id, { ...upd });
  };

  const handleThemeModeChange = (mode: ThemeMode) => {
    void updateThemeMode(mode);
  };

  const handleTabChange = (_: React.SyntheticEvent, value: TabValue) => {
    setTab(value);
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.currentTarget as SettingsButtonElement;
    if (target.openWithTab) {
      setTab(target.openWithTab);
      target.openWithTab = undefined;
    }
    setIsModalOpen(true);
  };

  const theme = useTheme();

  return (
    <>
      <Button id="settings-button" onClick={handleOpen} sx={{ color: (theme) => theme.palette.text.secondary }}>
        <Box
          component={"span"}
          fontSize={"1.625rem"}
          className="material-symbols-rounded fill"
        >
          settings
        </Box>
      </Button>
      <ModalWrapper
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2" fontWeight={600}>
              Settings
            </Typography>
            <IconButton
              size="small"
              onClick={() => setIsModalOpen(false)}
              component="span"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        }
      >
        <Box className={styles.Settings__TabContent}>
          <TabContext value={tab}>
            <TabList onChange={handleTabChange} variant="standard">
              <Tab label="Cloud Settings" value={TabValue.CloudSettings} />
              <Tab label="Appearance" value={TabValue.Appearance} />
              <Tab label="Advanced" value={TabValue.Advanced} />
              <Tab label="Structured Pro" value={TabValue.Subscription} />
            </TabList>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.CloudSettings}
            >
              <SubSettingSync
                settings={settings}
                triggerResync={() => props.triggerResync()}
              />
            </TabPanel>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.Appearance}
            >
              <SubSettingAppearance
                settings={settings}
                themeMode={themeMode}
                onThemeModeChange={handleThemeModeChange}
                onUpdate={handleSettingsUpdate}
              />
            </TabPanel>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.Advanced}
            >
              <SubSettingAdvanced
                settings={settings}
                onUpdate={handleSettingsUpdate}
              />
            </TabPanel>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.Subscription}
            >
              <SubSettingSubscription />
            </TabPanel>
          </TabContext>
        </Box>
      </ModalWrapper>
    </>
  );
};
