import PublicIcon from "@mui/icons-material/Public";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import type { UseTaskResponse } from "@structured/utils/hooks";
import { useState } from "react";

interface TaskSummaryProps {
  readonly task: UseTaskResponse["task"];
  readonly subtasks: UseTaskResponse["subtasks"];
  readonly completedSubtasks: UseTaskResponse["completedSubtasks"];
  readonly isRecurring: UseTaskResponse["isRecurring"];
  readonly completedAt: UseTaskResponse["completedAt"];
  readonly formattedTime: UseTaskResponse["formattedTime"];
  readonly formattedForeignTime: UseTaskResponse["formattedForeignTime"];
  readonly openEditModal: UseTaskResponse["openEditModal"];
  readonly note: UseTaskResponse["note"];
  readonly onExpand: () => void;
  readonly expanded: boolean;
}

export const TaskSummary = ({
  task,
  subtasks,
  completedSubtasks,
  isRecurring,
  completedAt,
  formattedTime,
  formattedForeignTime,
  openEditModal,
  note,
  onExpand,
  expanded,
}: TaskSummaryProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      onClick={(e) => {
        openEditModal();
        e.stopPropagation();
      }}
    >
      <Typography
        component="span"
        variant="caption"
        color="text.secondary"
        display="flex"
        alignItems="center"

      >
        {formattedTime}
        &nbsp;
        {isRecurring &&
          <Box
            component={"span"}
            fontSize={"0.875rem"}
            className="material-symbols-rounded"
          >
            sync
          </Box>
        }
        {task.timezone && (
          <Box component="span" >
            <Box
              component="span"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <PublicIcon fontSize="inherit" />
            </Box>

            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography
                padding="0.5rem"
                textAlign="center"
                variant="caption"
                color="text.secondary"
              >
                {formattedForeignTime}
              </Typography>
            </Popover>
          </Box>
        )}
      </Typography>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography
          fontSize={"1rem"}
          fontWeight={600}
          color={completedAt ? "text.secondary" : "text.primary"}
          sx={{
            opacity: completedAt ? 0.6 : 1,
            textDecoration: completedAt ? "line-through" : "none",
          }}
        >
          {task.title}
        </Typography>
        <Typography
          onClick={(e) => {
            e.stopPropagation();
            onExpand();
          }}
          component="span"
          color="text.secondary"
          fontSize={"0.8rem"}
          fontWeight={400}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={1.5}
        >
          {subtasks.length > 0 && (
            <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <Box
                  component={"span"}
                  fontSize={"1.25rem"}
                  className="material-symbols-rounded fill"
                >
                  check_box
                </Box>
              <Box component={"span"}>
                {completedSubtasks.length}/{subtasks.length}
              </Box>
            </Box>
          )}
          <Box>
            {note && (
              <Box
                component={"span"}
                fontSize={"1.25rem"}
                className="material-symbols-rounded"
                sx={{ verticalAlign: "middle" }}
              >
                sticky_note_2
              </Box>
            )}
            {(subtasks.length > 0 || note) && (
              <IconButton size="small" sx={{ pl: 0.5 }} disableRipple>
                <Box
                  component={"span"}
                  fontSize={"1.25rem"}
                  className="material-symbols-rounded"
                  sx={{
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.2s"
                  }}
                >
                  keyboard_arrow_down
                </Box>
              </IconButton>
            )}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};
