import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, IconButton, InputAdornment, Popover, TextField } from "@mui/material";
import { ColorSelector, Icon } from "@structured/components";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { type TmpTask } from "@structured/utils/tasks";
import { IconPickerPopover } from "@structured/views/IconPicker/IconPickerPopover";
import React, { useState } from "react";
import { type Control, Controller, type UseFormRegister, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TitleInputRowProps {
    control: Control<FormValues>;
    register: UseFormRegister<FormValues>;
    disabled?: boolean;
    isShowIconPicker: boolean;
    setIsShowIconPicker: (show: boolean) => void;
    setValue: UseFormSetValue<FormValues>;
    isEdit?: boolean;
    task: TmpTask;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}

const TitleInputRow: React.FC<TitleInputRowProps> = ({ control, register, disabled, task, isShowIconPicker, setIsShowIconPicker, setValue, onUpdateTmpTask }) => {
    const [iconAnchorEl, setIconAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [colorAnchorEl, setColorAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openIconPopover = Boolean(iconAnchorEl);
    const openColorPopover = Boolean(colorAnchorEl);
    const iconId = openIconPopover ? "icon-popover" : undefined;
    const colorId = openColorPopover ? "color-popover" : undefined;
    const handleColorChange = (color: string) => {
        setDisplayColor(color);
        onUpdateTmpTask({ ...task, color });
    };
    const handleTitleChange = (title: string) => {
        onUpdateTmpTask({ ...task, title });
    };
    const handleSymbolChange = (symbol: string) => {
        onUpdateTmpTask({ ...task, symbol });
    }

    const [displayColor, setDisplayColor] = useState(task.color);

    return (
        <TaskFormRowContainer>
            <Popover
                id={iconId}
                open={openIconPopover}
                anchorEl={iconAnchorEl}
                onClose={() => setIconAnchorEl(null)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                style={{
                    borderRadius: "1rem",
                }}
            >
                {isShowIconPicker && (
                    <Controller
                        control={control}
                        name="task.symbol"
                        disabled={disabled}
                        render={({ field }) => (
                            <IconPickerPopover
                                selectedIconId={field.value}
                                onClose={() => setIsShowIconPicker(false)}
                                onSelectIcon={(symbol) => {
                                    setIconAnchorEl(null);
                                    field.onChange(symbol ?? undefined);
                                    setIsShowIconPicker(false);
                                    handleSymbolChange(symbol);
                                }}
                            />
                        )}
                    />
                )}
            </Popover>
            <IconButton
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.main + "33",
                    borderRadius: "0.5rem",
                    width: "3rem",
                }}
                disableRipple
                disabled={disabled}
                onClick={(e) => {
                    setIsShowIconPicker(true);
                    setIconAnchorEl(e.currentTarget);
                }}
                aria-describedby={iconId}
            >
                <Icon
                    sx={{ color: (theme) => theme.palette.primary.main }}
                    name={getMaterialIdFromSymbolId(task.symbol)}
                />
            </IconButton>
            <TextField
                {...register("task.title", { required: true, disabled })}
                fullWidth
                type="text"
                autoFocus
                placeholder="Task Title"
                onChange={(e) => {
                    handleTitleChange(e.target.value);
                    setValue("task.title", e.target.value);
                }}
                InputProps={{
                    style: {
                        fontSize: "1.125rem",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "1.375rem",
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                onClick={(e) => setColorAnchorEl(e.currentTarget)}
                                aria-label="Add"
                                disableRipple
                                disableElevation
                                aria-describedby={colorId}
                                sx={{
                                    background: (theme) => theme.palette.mode === "dark" ? "#1C1C1E" : "#F8F8F8",
                                    position: "absolute",
                                    right: 0,
                                    height: "100%",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                            >
                                <Box component={"span"} sx={{ padding: "0.5rem" }} >
                                    <ColorSelector
                                        value={displayColor}
                                        onSelect={handleColorChange}
                                        onClose={() => setColorAnchorEl(null)}
                                        variant="button"
                                        disableMenu
                                    />
                                </Box>
                                <ExpandMoreIcon sx={{ transform: openColorPopover ? "rotate(180deg)" : "", color: (theme) => theme.palette.text.primary, marginRight: "0.5rem" }} fontSize="small" />
                            </Button>
                        </InputAdornment>
                    )
                }}
            />
            <Popover
                id={colorId}
                open={openColorPopover}
                anchorEl={colorAnchorEl}
                onClose={() => setColorAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <ColorSelector
                    value={task.color}
                    onSelect={handleColorChange}
                    variant="paper"
                />
            </Popover>

        </TaskFormRowContainer>
    );
};

export default TitleInputRow;