import { Box, Typography } from "@mui/material";
import { getThemeColor } from "@structured/utils/colors";
import { type TimelineEntry, TimelineEntryType } from "@structured/utils/timeline";
import React from "react";

import { TimelineDashedLine } from "./TimelineDashedLine";
import { TimelineNormalLine } from "./TimelineNormalLine";

export interface TimelineLineProps {
  readonly entry: TimelineEntry;
  readonly previousEntry?: TimelineEntry;
  readonly nextEntry?: TimelineEntry;
  readonly expanded?: boolean;
}

export const TimelineLine: React.FC<TimelineLineProps> = ({
  entry,
  previousEntry,
  expanded,
  nextEntry,
}) => {
  switch (entry.type) {
    case TimelineEntryType.TASK: {
      return (
        <Box
          component={"span"}
          display={"flex"}
          sx={{
            ".MuiTimelineItem-root:hover &": {
              background: (theme) =>
                theme.palette.mode === "light" ? "#F5F5F6" : "#3C3C4399",
            },
          }}
        >
          {entry.type === TimelineEntryType.TASK &&
            entry.isOverlapBefore && (
              <Box
                position={"absolute"}
                top={0}
                left={"20.75%"}
                width={"100%"}
                height={"100%"}
                display={"flex"}
                marginTop={"-1.125rem"}
                py={0.75}
              >
                <Typography
                  color={"text.secondary"}
                  fontSize={"0.75rem"}
                  fontWeight={400}
                  lineHeight={"20px"}
                >
                  Tasks are
                </Typography>
                <Typography
                  color={"primary.main"}
                  fontSize={"0.75rem"}
                  fontWeight={400}
                  lineHeight={"20px"}
                  pl={0.5}
                >
                  overlapping
                </Typography>
              </Box>
            )}
          <TimelineNormalLine
            expanded={expanded}
            entry={entry}
            isOverlapping={entry.isOverlapBefore}
            previousEntry={previousEntry}
            nextEntry={nextEntry}
            previousWasDashed={previousEntry?.type !== TimelineEntryType.TASK}
          />
        </Box >
      )
    }

    default: {
      return (
        <TimelineDashedLine
          nextEntry={nextEntry}
          previousEntry={previousEntry}
          entry={entry}
        />
      )
    }
  }
};
