import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import { Box, IconButton, TextField } from "@mui/material";
import { type TmpTask } from "@structured/utils/tasks";
import { type UseFormRegister, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
interface TaskNoteInputRowProps {
    task: TmpTask;
    disabled: boolean;
    register: UseFormRegister<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}

export const TaskNoteInputRow = (props: TaskNoteInputRowProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
                alignSelf: "stretch",
            }}
        >
            <TaskRowIcon disabled={props.disabled}>
                <NotesOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <TextField
                fullWidth
                placeholder="Add notes, meeting links or phone numbers..."
                multiline
                rows={4}
                onChange={(e) => {
                    props.setValue("task.note", e.target.value);
                    props.onUpdateTmpTask({ ...props.task, note: e.target.value });
                }}
                sx={{
                    display: "flex",
                    height: "9.375rem",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    gap: "0.875rem",
                    alignSelf: "stretch",
                }}
            />
        </Box>

    );
}
