import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CircleChecked from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineContent from "@mui/lab/TimelineContent";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { getThemeColor } from "@structured/utils/colors";
import { useTask } from "@structured/utils/hooks";
import { type TimelineTaskEntry } from "@structured/utils/timeline";
import classNames from "classnames";
import React from "react";

import { TaskSummary } from "./TaskSummary";
import styles from "./TimelineTask.module.scss";

export interface TimelineTaskProps {
  readonly entry: TimelineTaskEntry;
  readonly onExpand?: (expanded: boolean) => void;
  readonly expanded?: boolean;
}

export const TimelineTask: React.FC<TimelineTaskProps> = ({
  entry,
  onExpand,
  expanded,
}) => {
  const taskContext = useTask(entry.taskOrRecurring, entry.start);
  const openAccordion = () => {
    onExpand?.(!expanded);
  }
  return (
    <TimelineContent
      sx={{
        ".MuiTimelineItem-root:hover &": {
          background: (theme) =>
            theme.palette.mode === "light" ? "#F5F5F6" : "#3C3C4399"
        },
      }}
      className={styles.TimelineTask__ContentWrapper}
    >
      {!taskContext.subtasks?.length && !taskContext.task.note ? (
        <div className={styles.TimelineTask}>
          <TaskSummary {...taskContext} onExpand={openAccordion} expanded={expanded} />
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              sx={{ height: "max-content" }}
              onChange={taskContext.toggleCompletedAt}
              icon={
                <Box
                  fontSize={"1.5rem"}
                  component={"span"}
                  color={(theme) => getThemeColor(taskContext.task.color, theme)}
                  className="material-symbols-rounded"
                >
                  circle
                </Box>
              }
              checkedIcon={
                <Box
                  fontSize={"1.5rem"}
                  component={"span"}
                  color={(theme) => getThemeColor(taskContext.task.color, theme)}
                  className="material-symbols-rounded fill"
                >
                  check_circle
                </Box>
              }
              checked={!!taskContext.completedAt}
            />
          </div>
        </div>
      ) : (
        <Accordion expanded={expanded}>
          <AccordionSummary
            className={classNames(styles.TimelineTask, {
              [styles.isOverlapBefore]: entry.isOverlapBefore,
              [styles.isOverlapAfter]: entry.isOverlapAfter,
            })}
            sx={{
              "& .MuiAccordionSummary-content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                margin: 0,
                width: "100%"
              }
            }}
          >
            <TaskSummary {...taskContext} onExpand={openAccordion} expanded={expanded} />
            <div style={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  sx={{ height: "max-content" }}
                  onChange={taskContext.toggleCompletedAt}
                  icon={
                    <Box
                      fontSize={"1.5rem"}
                      component={"span"}
                      color={(theme) => getThemeColor(taskContext.task.color, theme)}
                      className="material-symbols-rounded"
                    >
                      circle
                    </Box>
                  }
                  checkedIcon={
                    <Box
                      fontSize={"1.5rem"}
                      component={"span"}
                      color={(theme) => getThemeColor(taskContext.task.color, theme)}
                      className="material-symbols-rounded fill"
                    >
                      check_circle
                    </Box>
                  }
                  checked={!!taskContext.completedAt}
                />
              </Box>
            </div>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingY: "1rem" }}>
            {taskContext.task.note && (
              <>
                <Divider
                  sx={{
                    ml: 1,
                    ".MuiTimelineItem-root:hover &": {
                      bgcolor: (theme) => getThemeColor(taskContext.task.color, theme),
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  color="text.primary"
                  fontSize={"0.8rem"}
                  fontWeight={400}
                  mt={1}
                  ml={1}
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {taskContext.task.note}
                </Typography>
                {taskContext.subtasks?.length > 0 && (
                  <Divider
                    sx={{
                      bgcolor: (theme) => theme.palette.divider,
                      border: "none",
                      height: 1.1,
                      margin: 1,
                      ".MuiTimelineItem-root:hover &": {
                        bgcolor: (theme) => getThemeColor(taskContext.task.color, theme),
                      },
                    }}
                  />
                )}
              </>
            )}
            {taskContext.subtasks?.map((subtask) => (
              <React.Fragment key={subtask.id}>
                <Box
                  component="fieldset"
                  sx={{ position: "relative" }}
                >
                  <FormControlLabel
                    onClick={(e) => e.stopPropagation()}
                    label={subtask.title}
                    sx={{
                      color: subtask.completed_at ? "text.secondary" : "text.primary",
                      "& .MuiFormControlLabel-label": {
                        textDecoration: subtask.completed_at ? "line-through" : "none",
                        fontSize: "0.8rem",
                        fontWeight: 400,
                        lineHeight: "20px",
                      }
                    }}
                    control={
                      <Checkbox
                        size="small"
                        checked={!!subtask.completed_at}
                        icon={
                          <CheckBoxOutlineIcon
                            sx={{
                              fontSize: "1.2rem",
                              color: (theme) => getThemeColor(taskContext.task.color, theme),
                            }}
                          />
                        }
                        checkedIcon={
                          <Box
                            fontSize={"1.2rem"}
                            color={(theme) => getThemeColor(taskContext.task.color, theme)}
                            component={"span"}
                            className="material-symbols-rounded fill"
                          >
                            check_box
                          </Box>
                        }
                        onClick={(e) => e.stopPropagation()}
                        onChange={(event, checked) => {
                          event.stopPropagation();
                          taskContext.updateSubtask(subtask.id ?? "", {
                            completed_at: checked
                              ? new Date().toISOString()
                              : null,
                          });
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </Box>
                {taskContext.subtasks[taskContext.subtasks.length - 1].id !== subtask.id && (
                  <Divider
                    sx={{
                      bgcolor: (theme) => theme.palette.divider,
                      border: "none",
                      height: 1.1,
                      margin: 1,
                      ".MuiTimelineItem-root:hover &": {
                        bgcolor: (theme) => getThemeColor(taskContext.task.color, theme),
                      },
                    }}
                  />
                )}

              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </TimelineContent>
  );
};
