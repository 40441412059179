import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { getThemeColor } from "@structured/utils/colors";
import {
  type TimelineAddTaskPromptEntry,
  type TimelineEntry,
  type TimelineGapMsgEntry,
} from "@structured/utils/timeline";
import React from "react";

export interface TimelineDashedLineProps {
  readonly entry: TimelineGapMsgEntry | TimelineAddTaskPromptEntry;
  readonly previousEntry?: TimelineEntry;
  readonly nextEntry?: TimelineEntry;
}

export const TimelineDashedLine: React.FC<TimelineDashedLineProps> = ({
  entry,
  previousEntry,
  nextEntry,
}) => {
  return (
    <TimelineSeparator>
      <TimelineConnector
        className="dashed"
        sx={{
          marginBottom: "0.1875rem",
          marginTop: "0.1875rem",
          background: (theme) =>
            entry.progress > 0
              ? entry.progress === 100 ?
                `linear-gradient(to bottom,  ${getThemeColor(entry.color, theme)},  ${getThemeColor(entry.color, theme)},  ${getThemeColor(nextEntry.color, theme)}, ${getThemeColor(nextEntry.color, theme)})`
                : `linear-gradient(${getThemeColor(entry.color, theme)} ${entry.progress}%, ${theme.palette.divider})`
              : theme.palette.divider,

          "&::after": {
            borderRightColor: (theme) => theme.palette.background.default,
          },
        }}
      />
    </TimelineSeparator>
  );
};
