
import { type Theme, Typography } from "@mui/material";


const dimPrimaryBackground = (theme: Theme) => {
    const hex = theme.palette.primary.main
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    const factor = 0.9;
    const newR = Math.floor(r * factor);
    const newG = Math.floor(g * factor);
    const newB = Math.floor(b * factor);
    return `#${newR.toString(16)}${newG.toString(16)}${newB.toString(16)}`;
}

export default dimPrimaryBackground;