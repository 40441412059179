import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Icon } from "@structured/components/Icon";
import { type Task } from "@structured/store";
import { getColor, getThemeColor } from "@structured/utils/colors";
import { today } from "@structured/utils/date";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import React from "react";

import styles from "./SelectDay.module.scss";

export interface SelectDayProps {
  title: string;
  date: Date;
  isSelected: boolean;
  dayNr: number;
  tasksForDay: (Task | RxRecurringNaked)[];
  onDaySelect: (day: Date) => void;
}

export const SelectDay: React.FC<SelectDayProps> = (props) => {
  const theme = useTheme();
  const processTasksArray = (tasks: (Task | RxRecurringNaked)[]) => {
    return tasks
      .filter(task => !(task as Task).is_in_inbox)
      .sort((a, b) => (a.start_time && b.start_time ? a.start_time - b.start_time : 0))
      .slice(0, 4);
  };

  const rgbaToHsl = (r: number, g: number, b: number, a = 1): string => {
    const normalizedR = r / 255;
    const normalizedG = g / 255;
    const normalizedB = b / 255;

    const max = Math.max(normalizedR, normalizedG, normalizedB);
    const min = Math.min(normalizedR, normalizedG, normalizedB);
    const delta = max - min;

    let lightness = (max + min) / 2;

    let saturation = 0;
    if (delta !== 0) {
      saturation = delta / (1 - Math.abs(2 * lightness - 1));
    }

    let hue = 0;
    if (delta !== 0) {
      switch (max) {
        case normalizedR:
          hue = 60 * (((normalizedG - normalizedB) / delta) + (normalizedG < normalizedB ? 6 : 0));
          break;
        case normalizedG:
          hue = 60 * ((normalizedB - normalizedR) / delta + 2);
          break;
        case normalizedB:
          hue = 60 * ((normalizedR - normalizedG) / delta + 4);
          break;
      }
    }

    lightness = lightness * a + (1 - a);

    const saturationPercent = Math.round(saturation * 100);
    const lightnessPercent = Math.round(lightness * 100);
    const roundedHue = Math.round(hue);

    return `hsl(${roundedHue}deg, ${saturationPercent}%, ${lightnessPercent}%)`;
  };

  const getRingColor = () => {
    const rgbaColor = (theme.palette.primary.light)
    const a = rgbaColor.split("(")[1].split(")")[0].split(",")
    const r = a[0]
    const g = a[1]
    const b = a[2]
    const alpha = a[3]

    const hslColor = rgbaToHsl(+r, +g, +b, +alpha)
    return hslColor
  }
  return (
    <Box
      id={props.isSelected ? "day_div" : ""}
      title={props.date.toDateString()}
      sx={{
        border: "1.5px solid",
        backgroundColor: props.isSelected ? "primary.light" : "transparent",
        borderColor: props.isSelected ? "primary.main" : "transparent",
        "&:hover": {
          backgroundColor: "primary.light",
          ".task-circle": {
            borderColor: getRingColor()
          }
        },
        color:
          +props.date < +today
            ? "text.secondary"
            : props.isSelected
              ? "text.primary"
              : "text.secondary",
      }}
      className={styles.Day}
      onClick={() => props.onDaySelect(props.date)}
    >
      <Box className="flex gap-1">
        <Typography color={+props.date === +today ? "primary" : ""}>
          {props.title}
        </Typography>
        <Typography
          color={+props.date === +today ? "primary" : "text.primary"}
          fontWeight="bold"
        >
          {props.dayNr}
        </Typography>
      </Box>

      <div className="flex justify-center items-center -space-x-1.5 rtl:space-x-reverse">
        {processTasksArray(props.tasksForDay).map((task, index) => (
          <Box
            className={`task-circle rounded-full border-[1.5px] relative transition-transform text-sm flex justify-center items-center ${index < 3 ? "h-[1.25rem] w-[1.25rem]" : "h-[0.9rem] w-[0.9rem]"}`}
            key={index}
            sx={{
              backgroundColor: (theme) => getThemeColor(task.color, theme),
              borderColor: !props.isSelected ? theme.palette.background.default : getRingColor(),
              zIndex: props.tasksForDay.length - index,
              color: "#FFFFFF",
            }}
          >
            {index < 3 && (
              <Icon
                sx={{
                  fontSize: "0.5375rem",
                  ml: index === 0 ? "0rem" : "0.1rem",
                }}
                name={getMaterialIdFromSymbolId(task.symbol)}
              />
            )}
          </Box>
        ))}
      </div>
    </Box>
  );
};
