import { AddOutlined } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TimelineContent from "@mui/lab/TimelineContent";
import Button from "@mui/material/Button";
import { styled, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useUI } from "@structured/store/ui/ui.provider";
import dimPrimaryBackground from "@structured/utils/colors/functions/dimPrimaryBackground";
import { useFirstFreePeriod } from "@structured/utils/hooks";
import { type TimelineAddTaskPromptEntry } from "@structured/utils/timeline";
import dayjs from "dayjs";

import styles from "./TimelineAddTaskPrompt.module.scss";

const StyledButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.main === "#F49F99" && "#DB7B74",
  },
}));

export const TimelineAddTaskPrompt = (props: {
  entry: TimelineAddTaskPromptEntry;
  selectedDay: Date;
}) => {
  const { setIsShowAddTaskModal, setAddTaskModalStartTime } = useUI();
  const theme = useTheme();

  const firstPossibleStartTime = useFirstFreePeriod(
    props.selectedDay,
    new Date(),
    props.entry.duration ?? 15
  );

  return (
    <TimelineContent>
      <div className={styles.TimelineAddTask}>
        <div className={styles.TimelineAddTask__Content}>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.TimelineAddTask__Message}
            onClick={() => {
              setAddTaskModalStartTime(firstPossibleStartTime.getHours() + firstPossibleStartTime.getMinutes() / 60);
              setIsShowAddTaskModal(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <ScheduleIcon fontSize="small" />
            &nbsp;Major strides in&nbsp;
            <Typography
              display="contents"
              variant="body2"
              color="primary"
              fontWeight={600}
              component="em"
            >
              {dayjs
                .duration(props.entry.duration, "minute")
                .format("H[h] mm[m]")}
            </Typography>
            ?
          </Typography>
        </div>
      </div>
    </TimelineContent>
  );
};
