import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Box, Button } from "@mui/material";
import { type TmpSubtask,type TmpTask } from "@structured/utils/tasks";
import React, { useEffect, useRef } from "react";

import { AddSubtaskInput } from "../AddSubtaskInput/AddSubtaskInput";
import { getNewTmpSubtask } from "../getNewTmpSubtask";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface SubTaskInputRowProps {
    task: TmpTask;
    disabled: boolean;
    appendSubtask: (subtask: TmpSubtask, options: { shouldFocus: boolean }) => void;
    updateSubtask: (index: number, subtask: TmpSubtask) => void;
    removeSubtask: (index: number) => void;
    subtasks: TmpSubtask[];
    onCompleteTmpSubtask?: (subtaskId: string, completed: boolean) => void;
    onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}

export const SubTaskInputRow = (props: SubTaskInputRowProps) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        if (inputRefs.current.length > 0) {
            inputRefs.current[props.subtasks.length - 1]?.focus();
        }
    }, [props.subtasks.length]);

    const handleAddSubtask = () => {
        props.appendSubtask(getNewTmpSubtask(), { shouldFocus: true });
        props.onUpdateTmpTask({ subtasks: [...props.subtasks, getNewTmpSubtask()] });
    };

    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index < props.subtasks.length - 1) {
                inputRefs.current[index + 1]?.focus();
            } else {
                handleAddSubtask();
            }
        }
    };

    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <TaskAltOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box sx={{ width: "100%" }}>
                <Button
                    disabled={props.disabled}
                    onClick={handleAddSubtask}
                    sx={{ marginTop: "0.4rem" }}
                >
                    + Add Subtask
                </Button>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "0.75rem" }}>
                    {props.subtasks.map((field, i) => (
                        <AddSubtaskInput
                            subtask={field}
                            disabled={props.disabled}
                            onUpdate={(update) => {
                                props.updateSubtask(i, { ...field, ...update, id: field.id })
                                props.onUpdateTmpTask({ subtasks: props.subtasks.map((subtask, j) => (i === j ? { ...subtask, ...update } : subtask)) });
                            }}
                            onComplete={(completed) => {
                                props.onCompleteTmpSubtask?.(field.id, completed);
                                props.updateSubtask(i, {
                                    ...field,
                                    completed_at: completed ? new Date().toISOString() : null,
                                });
                                props.onUpdateTmpTask({
                                    subtasks: props.subtasks.map((subtask, j) =>
                                        i === j ? { ...subtask, completed_at: completed ? new Date().toISOString() : null } : subtask
                                    ),
                                });
                            }}
                            onDelete={() => {
                                props.removeSubtask(i)
                                props.onUpdateTmpTask({ subtasks: props.subtasks.filter((_, j) => i !== j) });
                            }}
                            key={field.id}
                            inputRef={(el) => (inputRefs.current[i] = el)} // Store input refs
                            onKeyDown={(e) => handleKeyDown(i, e)} // Handle key down
                        />
                    ))}
                </Box>
            </Box>
        </TaskFormRowContainer>
    );
};
