import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { MobileTimePicker, TimePicker } from "@mui/x-date-pickers";
import { type TmpTask } from "@structured/utils/tasks";
import dayjs from "dayjs";
import advanced from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { type Control, Controller, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";
import TimeZoneSelector from "./TimeZoneSelector";

interface TimePickerRowProps {
    disabled: boolean;
    task: TmpTask;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    taskDuration: number;
    setTaskDuration: (duration: number) => void;
    timeZone: string;
    setTimeZone: (timezone: string) => void;
    readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
}

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

export const TimePickerRow = (props: TimePickerRowProps) => {
    const endTime = props.taskDuration === 1
        ? dayjs().startOf("day").add(props.task.start_time, "hour")
        : dayjs().startOf("day").add(props.task.start_time + props.taskDuration / 60, "hour");

    const handleTimezoneChange = (timezone: string) => {
        props.setTimeZone(timezone);
    }

    const generateTooltip = (task: TmpTask) => {
        try {

            let taskTimeZone = props.timeZone
            if (taskTimeZone === "floating") {
                taskTimeZone = dayjs.tz.guess();
            }
            const userTimeZone = dayjs.tz.guess();

            const taskDay = task.day ? dayjs.tz(task.day, taskTimeZone) : dayjs.tz(new Date(), taskTimeZone);
            const startTimeInTaskTZ = taskDay.hour(task.start_time).minute(0);

            const endTimeInTaskTZ = startTimeInTaskTZ.add(task.duration, "minute");

            const startTimeInLocal = startTimeInTaskTZ.tz(userTimeZone);
            const endTimeInLocal = endTimeInTaskTZ.tz(userTimeZone);

            const formattedStart = startTimeInLocal.format("h:mm A");
            const formattedEnd = endTimeInLocal.format("h:mm A");
            const localTimeZoneAbbreviation = startTimeInLocal.format("z");

            const startDayDiff = startTimeInLocal.date() - taskDay.date();
            const endDayDiff = endTimeInLocal.date() - taskDay.date();

            const startIndicator = startDayDiff > 0 ? `<sup>+${startDayDiff}</sup>` : "";
            const endIndicator = endDayDiff > 0 ? `<sup>+${endDayDiff}</sup>` : "";

            return `${formattedStart}${startIndicator} - ${formattedEnd}${endIndicator} (${localTimeZoneAbbreviation})`;
        } catch (e) {
            return "";
        }
    };

    const onUpdate_start_time = (start_time: number) => {
        props.onUpdateTmpTask({ ...props.task, start_time });
    }

    const onUpdate_duration = (duration: number) => {
        props.onUpdateTmpTask({ ...props.task, duration });
    }

    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <AccessAlarmOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Controller
                    control={props.control}
                    name="task.start_time"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <TimePicker
                            disableOpenPicker
                            sx={{
                                width: "7.5rem",
                            }}
                            value={dayjs().startOf("day").add(value, "hour")}
                            onChange={(ev) => {

                                onChange(
                                    dayjs
                                        .duration({
                                            hours: ev.hour(),
                                            minutes: ev.minute(),
                                        })
                                        .asHours()
                                )
                                onUpdate_start_time(
                                    dayjs
                                        .duration({
                                            hours: ev.hour(),
                                            minutes: ev.minute(),
                                        })
                                        .asHours()
                                )
                            }}
                            {...field}
                        />
                    )}
                />
                <ArrowForwardIcon
                    color="action"
                    sx={{
                        height: "1.25rem",
                        flexShrink: 0,
                    }}
                />
                <Controller
                    control={props.control}
                    name="task.duration"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <TimePicker
                            disableOpenPicker
                            sx={{
                                width: "7.5rem",
                                textAlign: "center",
                            }}
                            value={endTime}
                            onChange={(ev) => {
                                const startTime = props.task.start_time;
                                const newEndTime = dayjs.duration({
                                    hours: ev.hour(),
                                    minutes: ev.minute(),
                                }).asHours();

                                if (newEndTime < startTime) {
                                    props.setValue("task.start_time", newEndTime);
                                    props.setTaskDuration(1);
                                    onChange(1);
                                    onUpdate_start_time(newEndTime);
                                    onUpdate_duration(1);
                                } else {
                                    const durationInMinutes = Math.round((newEndTime - startTime) * 60);
                                    props.setTaskDuration(durationInMinutes);
                                    onChange(durationInMinutes);
                                    onUpdate_duration(durationInMinutes);
                                }
                            }}
                            {...field}
                        />
                    )}
                />
                <TimeZoneSelector
                    disabled={props.disabled}
                    time={dayjs().startOf("day").add(props.task.start_time, "hour").format()}
                    setTimezone={handleTimezoneChange}
                    setValue={props.setValue}
                    timeZone={props.timeZone}
                    taskDuration={props.taskDuration}
                    toolTipText={generateTooltip(props.task)}
                />
            </Box>
        </TaskFormRowContainer>
    );
};