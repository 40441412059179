import MuiTimeline from "@mui/lab/Timeline";
import MuiTimelineItem from "@mui/lab/TimelineItem";
import MuiTimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Box } from "@mui/material";
import { useUI } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import { lazySetInterval } from "@structured/utils/common";
import { useDailyTimelineEntries } from "@structured/utils/hooks";
import {
  TimelineEntryType,
  type TimelineTaskEntry,
} from "@structured/utils/timeline";
import { useEffect, useState } from "react";

import { TimelineAddTaskPrompt } from "./TimelineAddTaskPrompt/TimelineAddTaskPrompt";
import { TimelineGapMsg } from "./TimelineGapMsg/TimelineGapMsg";
import { TimelineLine } from "./TimelineLine/TimelineLine";
import { TimelineTask } from "./TimelineTask/TimelineTask";
import { TimelineTimes } from "./TimelineTimes/TimelineTimes";

// TODO set to one minute for prod
const RE_RENDER_TIMELINE_INTERVAL = 10 * 1000;

export const Timeline = (props: { day: Date }) => {
  const [now, setNow] = useState(new Date());
  const timelineEntries = useDailyTimelineEntries(props.day, now);
  const { setContextModalTask } = useUI();

  const [expandedTask, setExpandedTask] = useState<TimelineTaskEntry>();


  const handleExpand = (expanded: boolean, entry: TimelineTaskEntry) => {
    if (expanded) {
      setExpandedTask(entry);
    } else {
      setExpandedTask(undefined);
    }
  };

  const handleRowClick = (entry: TimelineTaskEntry) => {
    setContextModalTask({
      taskOrRecurring: entry.taskOrRecurring,
      dayTimestamp: entry.start.getTime(),
    });
  };

  useEffect(() => {
    return lazySetInterval(() => {
      setNow(new Date());
    }, RE_RENDER_TIMELINE_INTERVAL);
  }, []);

  return (
    <MuiTimeline
      sx={{
        "@media (max-width: 600px)": {
          padding: "0.375rem 0.5rem",
        }
      }}
    >
      {timelineEntries.map((entry, index) => (
        <MuiTimelineItem
          key={index}
          onClick={() => entry.type === TimelineEntryType.TASK && handleRowClick(entry)}
          sx={{
            "@media (max-width: 600px)": {
              "&::before": {
                display: "none",
              },
            }
          }}
        >
          <MuiTimelineOppositeContent
            sx={{
              flex: {
                xs: "0 0 4.6875rem",
                sm: "0 0 6.875rem",
              },
              paddingLeft: {
                xs: "0.25rem",
                sm: "1rem",
              },
              paddingRight: {
                xs: "0.25rem",
                sm: "1rem",
              },
              ".MuiTimelineItem-root:hover &": {
                background: (theme) =>
                  entry.type === "TASK" && (theme.palette.mode === "light" ? "#F5F5F6" : "#3C3C4399"),
                borderTopLeftRadius: "1rem",
                borderBottomLeftRadius: "1rem",
              },
            }}
          >
            <TimelineTimes entry={entry} />
          </MuiTimelineOppositeContent>

          <TimelineLine
            entry={entry}
            nextEntry={timelineEntries[index + 1] ?? undefined}
            previousEntry={timelineEntries[index - 1] ?? undefined}
            expanded={
              entry.type === TimelineEntryType.TASK &&
              entry.taskOrRecurring.id === expandedTask?.taskOrRecurring.id
            }
          />

          {entry.type === TimelineEntryType.TASK && (
            <TimelineTask
              entry={entry}
              onExpand={(expanded) => handleExpand(expanded, entry)}
              expanded={
                entry.taskOrRecurring.id === expandedTask?.taskOrRecurring.id
              }
            />
          )}
          {entry.type === TimelineEntryType.ADD_TASK_PROMPT && (
            <TimelineAddTaskPrompt
              entry={entry}
              selectedDay={props.day}
            />
          )}
          {entry.type === TimelineEntryType.GAP_MSG && (
            <TimelineGapMsg entry={entry} />
          )}
        </MuiTimelineItem>
      ))}
    </MuiTimeline>

  );
};
